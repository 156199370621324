@use "../misc";

.home {
  height: 768px;
  position: relative;
}

.home::after {
  content: " ";
  position: absolute;
  background: url("../../src/images/illustration-section-01.svg");
  width: 100%;
  height: 768px;
  top: 0;
  left: 50%;
  background-size: cover;
  background-position: center top;
  transform: translate(-50%);
  z-index: -1;
}

.hero {
  padding-top: 6rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @include misc.respond-to("large") {
    display: block;
    text-align: left;
    padding-left: 2rem;
  }
}

.hero-heading {
  max-width: 15ch;
}

.hero-text {
  color: misc.$gray;
  margin-top: 1.5rem;
  max-width: 25ch;
  @include misc.respond-to("medium") {
    margin-top: 2.5rem;
    max-width: 35ch;
  }
  @include misc.respond-to("large") {
    margin-top: 2.5rem;
    max-width: 45ch;
  }
}

.cta-btn {
  width: max-content;
  padding: 1rem 1rem;
  display: block;
  margin-top: 2rem;
}
