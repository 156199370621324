@keyframes slide-down {
  0% {
    height: 0%;
  }
  50% {
    height: 50%;
  }
  100% {
    height: 100%;
  }
}
