html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
