@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;500&family=IBM+Plex+Sans:wght@600&display=swap");

body {
  font-family: "Heebo", sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4 {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
}

.btn {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
}
